import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

export const PaperStyle = styled(Paper)`
  .div {
    padding: 1px 5px;
    h1 {
      font-weight: bold;
      color: rgba(80, 80, 80, 0.91);
      font-size: 12px;
      margin-bottom: 10px;
    }
    button {
      height: 100%;
      margin-top: 32px;
      margin-left: 11px;
    }
  }
`;

export const Pesquisa = styled.div`
  margin-bottom: 20px;
`;

export const FormAuto = styled.form`
  .MTableToolbar-root-200 {
    display: none !important;
  }
  .MuiTableCell-sizeSmall {
    padding: 3px 5px 3px 7px;
}

.myTableClass {
  font-size: 18px;
}

.inputCustom {
  border: 2px solid #000;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
}
  input {
   
    border-radius: 5px;
    padding: 0.2525rem 0.15rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: rgb(73, 80, 87);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(206, 212, 218);
    position: relative;
    width: 100%;
    z-index: 1;


    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label {
      font-weight: bold;
    }
  }
  select {
      min-height: calc(1.8em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      position: relative;
      width: 100%;
      z-index: 11;


    }
  .firstline {
    justify-content: space-around;
    padding: 10px;
    background: white;
    margin-top: 0px;
    flex-wrap: wrap;
    width: 100%;
    font-size: 14px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    z-index: 500;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  textarea {
    min-height: 4.2rem;
    border-radius: 5px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: rgb(73, 80, 87);
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border: 1px solid rgb(206, 212, 218);
    position: relative;
    width: 90%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const Form = styled.form``;

export const Input = styled.input`
  height: calc(1em + 0.35rem + 1px);
  padding: 0.175rem 0.35rem;
  font-size: 10px;
  font-weight: 200;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  position: relative;
  max-width: 10%;
`;

export const Select = styled.select`
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  position: relative;
  width: 80%;
  z-index: 11;

`;

export const ButtonStyled = styled.button`
  margin-top: 1px;
  margin-right: 10px;
  margin-left: 0px;
  padding: 2px;
  border: 0 none;
  border-radius: 5px;
  background-color: #00acc1;
  font-size: 2px;
  font-weight: bold;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
  width: 100px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const ButtonRequest = styled.button.attrs(props => ({
  bg: props.bg
}))`
  margin-top: 17px;
  margin-right: 15px;
  padding: 9px;
  border: 0 none;
  border-radius: 5px;
  background-color: ${props => props.bg};
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
export const Div = styled.div`
  z-index: 3;
`;
